import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const Styles = styled.div`
  margin: 3rem auto;
  display: flex;
  max-width: 1024px;

  &>* {
    width: 50%;
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    margin: 1rem 0;
    flex-direction: column;
    
    &>* {
      width: 100%;
    }
  }
`;

const TextArea = styled.div`
  p {
    margin: 2rem 0;
  }

  margin-left: 4rem;

  @media(max-width: ${p => p.theme.breakpoints.xl}) {
    p {
      margin: 1rem 0;
    }
  }

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    margin-left: 1rem;
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    margin-left: 0;
    margin-top: 1rem;

    p {
      margin: 1rem 0;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  font-weight: ${p => p.theme.fonts.weights.bold};
`;

const ImageArea = styled.div`
  img {
    width: 100%;
  }
`;

export default ({ imageUrl, aboutTitle, rolunk }) => (
  <Styles>
    <ImageArea>
      <img src={imageUrl} alt={aboutTitle} />
    </ImageArea>
    <TextArea>
      <Title>{rolunk.cim}</Title>
      <ReactMarkdown>{rolunk.rolunk}</ReactMarkdown>
    </TextArea>
  </Styles>
);
