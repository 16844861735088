import React from 'react';
import styled from 'styled-components';

import Carousel from './carousel';
import PageTitle from '../ui/pageTitle';

const Styles = styled.div`
  margin: 3rem auto;
`;

const CarouselStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 3rem auto;
  max-width: 1024px;

  position: relative;

  height: 18rem;

  &>* {
    width: 100%;
    height: 100%;
  }
`;

export default ({ title, titles }) => (
  <Styles>
    <PageTitle center>{ title }</PageTitle>
    <CarouselStyles>
      <Carousel items={titles} smallBottom />
    </CarouselStyles>
  </Styles>
);
