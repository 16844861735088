import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import PageTitle from '../components/ui/pageTitle';
import AboutUs from '../components/composite/aboutUs';
import SaidAboutUs from '../components/composite/saidAboutUs';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          translations {
            about
            aboutSubTitle
            saidAboutUs
          }
        }
      }

      allStrapiRolunk {
        edges {
          node {
            strapiId
            cim
            rolunk
            kep {
              url
              formats
            }
          }
        }
      }

      allStrapiRolunkMondtak {
        edges {
          node {
            szoveg
            kep {
              url
            }
          }
        }
      }
    }
  `);

  const {
    about: aboutTitle,
    aboutSubTitle,
    saidAboutUs: saidAboutUsTitle,
  } = data.site.siteMetadata.translations;

  const rolunk = data.allStrapiRolunk.edges.map(node => node.node)[0];
  const rolunkMondtaks = data.allStrapiRolunkMondtak.edges.map(node => node.node);

  const imageUrl = rolunk?.kep?.formats?.large?.url || rolunk?.kep?.url;

  return (
    <Layout>
      <SEO title={aboutTitle} />
      <PageTitle center>{aboutTitle}</PageTitle>
      <PageTitle subTitle center>{aboutSubTitle}</PageTitle>
      <AboutUs aboutTitle={aboutTitle} rolunk={rolunk} imageUrl={imageUrl} />
      <SaidAboutUs title={saidAboutUsTitle} titles={rolunkMondtaks} />
    </Layout>
  );
};
